<template>
  <svg
    class="custom-container-icon v-icon__svg"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <g id="Ebene_3"></g>
    <g id="Ebene_1" class="st0">
      <g>
        <path
          class="st1"
          d="M74.7,47.1V93c0,1.1-0.9,2.1-2.1,2.1H26.7c-1.1,0-2.1-0.9-2.1-2.1V47.1"
        />
        <path
          class="st2"
          d="M71.2,28.4l14.5,15.8c0.8,0.8,0.2,2-0.8,2H14.5c-1.1,0-1.6-1.3-0.8-2L48.8,5.4c0.5-0.5,1.2-0.5,1.7,0l13.7,15
                "
        />
        <line class="st2" x1="31.3" y1="64.1" x2="43" y2="52.5" />
        <line class="st2" x1="30.4" y1="82.8" x2="60.7" y2="52.5" />
        <line class="st2" x1="42.6" y1="88.3" x2="69" y2="62" />
        <line class="st2" x1="60.4" y1="88.3" x2="67.7" y2="81" />
      </g>
      <g class="st3">
        <path
          class="st4"
          d="M87,43L72.5,27.3c-0.7-0.7-1.8-0.8-2.5-0.1c-0.7,0.7-0.8,1.8-0.1,2.5l13.7,14.9H15.7l34-37.4l13.2,14.6
                c0.6,0.7,1.8,0.8,2.5,0.1c0.7-0.6,0.8-1.8,0.1-2.5L51.8,4.2c-1.1-1.1-3-1.2-4.2,0.1L12.4,43c-0.8,0.8-1.1,2.1-0.6,3.2
                c0.5,1.1,1.5,1.8,2.7,1.8h8.4v45c0,2.1,1.7,3.8,3.8,3.8h45.9c2.1,0,3.8-1.7,3.8-3.8V48h8.4c1.2,0,2.3-0.7,2.7-1.8S87.8,43.8,87,43
                z M73,93c0,0.2-0.1,0.3-0.3,0.3H26.7c-0.2,0-0.3-0.1-0.3-0.3V48H73V93z"
        />
        <path
          class="st4"
          d="M31.3,65.9c0.4,0,0.9-0.2,1.2-0.5l11.7-11.7c0.7-0.7,0.7-1.8,0-2.5s-1.8-0.7-2.5,0L30.1,62.9
                c-0.7,0.7-0.7,1.8,0,2.5C30.4,65.7,30.9,65.9,31.3,65.9z"
        />
        <path
          class="st4"
          d="M30.4,84.5c0.4,0,0.9-0.2,1.2-0.5L62,53.7c0.7-0.7,0.7-1.8,0-2.5s-1.8-0.7-2.5,0L29.2,81.5
                c-0.7,0.7-0.7,1.8,0,2.5C29.5,84.3,30,84.5,30.4,84.5z"
        />
        <path
          class="st4"
          d="M41.4,89.6c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5l26.3-26.3c0.7-0.7,0.7-1.8,0-2.5s-1.8-0.7-2.5,0
                L41.4,87.1C40.7,87.8,40.7,88.9,41.4,89.6z"
        />
        <path
          class="st4"
          d="M66.5,79.7l-7.3,7.3c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5l7.3-7.3
                c0.7-0.7,0.7-1.8,0-2.5S67.2,79.1,66.5,79.7z"
        />
      </g>
    </g>
    <g id="Ebene_2" class="st0">
      <g>
        <circle class="st2" cx="36.3" cy="62.9" r="5.3" />
        <circle class="st2" cx="63.7" cy="62.9" r="5.3" />
        <line class="st2" x1="34.9" y1="76.2" x2="21.8" y2="95.4" />
        <line class="st2" x1="65.3" y1="75.7" x2="79.9" y2="95.8" />
        <line class="st2" x1="38.2" y1="82.1" x2="62" y2="82.1" />
        <line class="st2" x1="31.8" y1="91.3" x2="68.4" y2="91.3" />
        <path
          class="st2"
          d="M20.1,31.5v-7.3c0-0.9,0.3-5.5,3.4-7c0,0,4.8-3.7,26.8-3.7c22.6,0,26.1,3.7,26.1,3.7c2.5,1.5,3.4,6.2,3.4,7
                v49.5c0,0.7-0.6,1.3-1.3,1.3H21.4c-0.7,0-1.3-0.6-1.3-1.3V43.7"
        />
        <path
          class="st2"
          d="M29.7,46.2c0,0.6,0.8,1,1.8,1h36.9c1,0,1.8-0.5,1.8-1V24.5c0-0.6-0.9-1.1-1.9-1.1H31.7
                c-1.1,0-1.9,0.5-1.9,1.1V46.2z"
        />
        <polyline class="st2" points="42.8,13 42.8,5 57.2,5 57.2,13 		" />
        <line class="st2" x1="36.3" y1="5" x2="63.7" y2="5" />
      </g>
      <g class="st3">
        <path
          class="st4"
          d="M29.3,62.9c0,3.9,3.2,7,7,7s7-3.2,7-7s-3.2-7-7-7S29.3,59,29.3,62.9z M39.9,62.9c0,1.9-1.6,3.5-3.5,3.5
                s-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5S39.9,60.9,39.9,62.9z"
        />
        <path
          class="st4"
          d="M56.6,62.9c0,3.9,3.2,7,7,7s7-3.2,7-7s-3.2-7-7-7S56.6,59,56.6,62.9z M67.2,62.9c0,1.9-1.6,3.5-3.5,3.5
                s-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5S67.2,60.9,67.2,62.9z"
        />
        <path
          class="st4"
          d="M81.3,94.8l-13-18h10.1c1.7,0,3.1-1.4,3.1-3.1V24.2c0-1.2-1-6.4-4.1-8.4c-1-0.8-5-3.1-18.5-3.8V6.8h4.8
                c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8h-4.8v0H41.1v0h-4.8c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h4.8V12
                c-13.2,0.8-17.6,3-18.5,3.6c-4.2,2.2-4.2,8.3-4.2,8.5v7.3c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-7.3c0-0.9,0.4-4.5,2.4-5.4
                l0.2-0.1c0,0,5.2-3.3,25.7-3.3c20,0,24.6,2.9,24.9,3.2l0.3,0.2c1.6,1,2.5,4.7,2.5,5.5v49.1H21.8V43.7c0-1-0.8-1.8-1.8-1.8
                s-1.8,0.8-1.8,1.8v30.1c0,1.7,1.4,3.1,3.1,3.1h10.9l-12,17.6c-0.5,0.8-0.3,1.9,0.5,2.4c0.3,0.2,0.6,0.3,1,0.3
                c0.6,0,1.1-0.3,1.4-0.8l13.1-19.2c0.1-0.1,0.1-0.2,0.2-0.3H64l14.5,20c0.3,0.5,0.9,0.7,1.4,0.7c0.4,0,0.7-0.1,1-0.3
                C81.7,96.7,81.9,95.6,81.3,94.8z M44.6,11.9V6.8h10.9v5.1c-1.6,0-3.4-0.1-5.2-0.1C48.2,11.8,46.3,11.8,44.6,11.9z"
        />
        <path
          class="st4"
          d="M62,83.9c1,0,1.8-0.8,1.8-1.8S63,80.4,62,80.4H38.2c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8H62z"
        />
        <path
          class="st4"
          d="M68.4,89.5H31.8c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h36.7c1,0,1.8-0.8,1.8-1.8S69.4,89.5,68.4,89.5z"
        />
        <path
          class="st4"
          d="M72,46.2V24.5c0-1.6-1.6-2.9-3.7-2.9H31.7c-2.1,0-3.7,1.2-3.7,2.9v21.7c0,1.6,1.5,2.8,3.6,2.8h36.9
                C70.5,48.9,72,47.7,72,46.2z M68.5,45.4C68.5,45.4,68.5,45.4,68.5,45.4l-37,0c0,0,0,0-0.1,0V25.2c0.1,0,0.1,0,0.2,0h36.7
                c0.1,0,0.1,0,0.2,0V45.4z"
        />
      </g>
    </g>
    <g id="Ebene_4" class="st0">
      <g class="st3">
        <line class="st5" x1="42.5" y1="25.7" x2="42.5" y2="59" />
        <line class="st5" x1="30.2" y1="25.7" x2="30.2" y2="59" />
        <line class="st5" x1="17.8" y1="25.7" x2="17.8" y2="59" />
        <line class="st5" x1="54.9" y1="25.7" x2="54.9" y2="59" />
        <line class="st5" x1="67.2" y1="25.7" x2="67.2" y2="59" />
        <line class="st5" x1="79.6" y1="25.7" x2="79.6" y2="59" />
        <line class="st5" x1="91.9" y1="25.7" x2="91.9" y2="59" />
        <path
          class="st5"
          d="M92.6,64.7h8.7c1.4,0,2.6-1.2,2.6-2.6V21.7c0-1.4-1.2-2.6-2.6-2.6H9.4c-1.4,0-2.6,1.2-2.6,2.6v8.4"
        />
        <line class="st5" x1="40.4" y1="64.7" x2="66.7" y2="64.7" />
        <path class="st5" d="M6.8,40.1v22c0,1.4,1.2,2.6,2.6,2.6H17" />
        <line class="st5" x1="43.5" y1="71.8" x2="62.1" y2="71.8" />
        <line class="st5" x1="8.2" y1="71.8" x2="10.9" y2="71.8" />
        <path class="st5" d="M95.6,71.8H111c0.9,0,1.7-0.7,1.7-1.7V17" />
        <path
          class="st5"
          d="M119.6,17h22.6c0.9,0,1.8,0.4,2.4,1.1c1.9,2.1,5.4,7.4,5.9,18.3c0.2,4.6,0,28.5,0,37c0,0.5-0.7,0.7-0.9,0.2
                c-1.9-4.7-7-13.8-17.7-14.2c-14.9-0.5-19.3,12.4-19.3,12.4"
        />
        <path
          class="st5"
          d="M119.4,38.5c0,0.5,0.5,0.9,1.2,0.9l23.6,0c0.7,0,1.2-0.4,1.2-0.9l-2.2-13.4c0-0.5-0.6-0.9-1.3-0.9h-21.2
                c-0.7,0-1.3,0.4-1.3,0.9V38.5z"
        />
        <circle class="st5" cx="78.6" cy="77" r="10.8" />
        <circle class="st5" cx="27.4" cy="77" r="10.8" />
        <circle class="st5" cx="131.5" cy="77" r="10.8" />
      </g>
      <g class="st3">
        <path
          class="st4"
          d="M44.3,59V25.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V59c0,1,0.8,1.8,1.8,1.8S44.3,59.9,44.3,59z"
        />
        <path
          class="st4"
          d="M31.9,59V25.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V59c0,1,0.8,1.8,1.8,1.8S31.9,59.9,31.9,59z"
        />
        <path
          class="st4"
          d="M19.6,59V25.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V59c0,1,0.8,1.8,1.8,1.8S19.6,59.9,19.6,59z"
        />
        <path
          class="st4"
          d="M56.6,59V25.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V59c0,1,0.8,1.8,1.8,1.8S56.6,59.9,56.6,59z"
        />
        <path
          class="st4"
          d="M69,59V25.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V59c0,1,0.8,1.8,1.8,1.8S69,59.9,69,59z"
        />
        <path
          class="st4"
          d="M81.3,59V25.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V59c0,1,0.8,1.8,1.8,1.8S81.3,59.9,81.3,59z"
        />
        <path
          class="st4"
          d="M93.6,59V25.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8V59c0,1,0.8,1.8,1.8,1.8S93.6,59.9,93.6,59z"
        />
        <path
          class="st4"
          d="M105.7,62.1V21.7c0-2.4-2-4.4-4.4-4.4H9.4c-2.4,0-4.4,2-4.4,4.4v8.4c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8
                v-8.4c0-0.5,0.4-0.9,0.9-0.9h91.9c0.5,0,0.9,0.4,0.9,0.9v40.3c0,0.5-0.4,0.9-0.9,0.9h-8.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8
                h8.7C103.7,66.4,105.7,64.5,105.7,62.1z"
        />
        <path
          class="st4"
          d="M68.4,64.7c0-1-0.8-1.8-1.8-1.8H40.4c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h26.2
                C67.6,66.4,68.4,65.6,68.4,64.7z"
        />
        <path
          class="st4"
          d="M17,66.4c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H9.4c-0.5,0-0.9-0.4-0.9-0.9v-22c0-1-0.8-1.8-1.8-1.8
                s-1.8,0.8-1.8,1.8v22c0,2.4,2,4.4,4.4,4.4H17z"
        />
        <path
          class="st4"
          d="M62.1,70.1H43.5c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h18.6c1,0,1.8-0.8,1.8-1.8S63,70.1,62.1,70.1z"
        />
        <path
          class="st4"
          d="M10.9,70.1H8.2c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h2.7c1,0,1.8-0.8,1.8-1.8S11.9,70.1,10.9,70.1z"
        />
        <path
          class="st4"
          d="M152.3,36.3c-0.6-11.3-4.3-17-6.4-19.4c-1-1.1-2.3-1.7-3.7-1.7h-22.6c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8
                h22.6c0.4,0,0.8,0.2,1.1,0.5c1.5,1.6,5,6.6,5.5,17.3c0.2,3.5,0.1,19.2,0,29.5c0,0.8,0,1.5,0,2.2c-2.9-4.7-8.2-10.2-16.8-10.5
                c-9-0.3-14.5,3.9-17.6,7.7V17c0-1-0.8-1.8-1.8-1.8S111,16,111,17v53.1H95.6c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8H111
                c0.3,0,0.7-0.1,1-0.2c0,0,0.1,0,0.1,0.1c0.9,0.3,1.9-0.2,2.2-1.1c0.2-0.5,4.2-11.7,17.5-11.2c9.5,0.3,14.2,8.4,16.1,13.1
                c0.3,0.9,1.2,1.4,2,1.4c0.1,0,0.3,0,0.4,0c1.1-0.2,1.8-1.1,1.8-2.2c0-1.9,0-4.5,0-7.4C152.4,55,152.5,40,152.3,36.3z"
        />
        <path
          class="st4"
          d="M120.6,41.2l23.6,0c1.6,0,2.9-1.2,2.9-2.6L144.9,25c-0.1-1.4-1.4-2.5-3-2.5h-21.2c-1.7,0-3,1.2-3,2.7v13.3
                C117.6,40,118.9,41.2,120.6,41.2z M121.1,26h20.4l1.9,11.7l-22.3,0V26z"
        />
        <path
          class="st4"
          d="M78.6,64.4C71.6,64.4,66,70.1,66,77c0,6.9,5.6,12.6,12.6,12.6c6.9,0,12.6-5.6,12.6-12.6
                C91.1,70.1,85.5,64.4,78.6,64.4z M78.6,86.1c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1S83.6,86.1,78.6,86.1z"
        />
        <path
          class="st4"
          d="M27.4,64.4c-6.9,0-12.6,5.6-12.6,12.6c0,6.9,5.6,12.6,12.6,12.6S40,83.9,40,77C40,70.1,34.3,64.4,27.4,64.4z
                M27.4,86.1c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1S32.4,86.1,27.4,86.1z"
        />
        <path
          class="st4"
          d="M131.5,64.4c-6.9,0-12.6,5.6-12.6,12.6c0,6.9,5.6,12.6,12.6,12.6c6.9,0,12.6-5.6,12.6-12.6
                C144,70.1,138.4,64.4,131.5,64.4z M131.5,86.1c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1S136.5,86.1,131.5,86.1z"
        />
      </g>
    </g>
    <g id="Ebene_5" class="st0">
      <g>
        <path
          class="st2"
          d="M6.2,80.7h87.6c1,0,1.7-0.8,1.7-1.7v-6.4c0-1-0.8-1.7-1.7-1.7H6.2c-1,0-1.7,0.8-1.7,1.7V79
                C4.5,79.9,5.3,80.7,6.2,80.7z"
        />
        <path
          class="st2"
          d="M89,39.8v28.7c0,1-0.8,1.7-1.7,1.7H12.7c-1,0-1.7-0.8-1.7-1.7V21.4c0-1,0.8-1.7,1.7-1.7h74.6
                c1,0,1.7,0.8,1.7,1.7v8.2"
        />
        <path
          class="st2"
          d="M52.6,28.1l2.4,5.5c0.4,1,1.4,1.5,2.5,1.4l6-0.7c0.5-0.1,0.9,0.2,1.1,0.6l1.4,2.5c0.2,0.4,0.2,0.9-0.1,1.3
                l-3.6,4.9c-0.6,0.9-0.6,2,0,2.9l3.6,4.9c0.3,0.4,0.3,0.9,0.1,1.3l-1.4,2.5c-0.2,0.4-0.7,0.6-1.1,0.6l-6-0.7c-1-0.1-2,0.5-2.5,1.4
                L52.6,62c-0.2,0.4-0.6,0.7-1.1,0.7h-2.9c-0.5,0-0.9-0.3-1.1-0.7l-2.4-5.5c-0.4-1-1.4-1.5-2.5-1.4l-6,0.7c-0.5,0.1-0.9-0.2-1.1-0.6
                l-1.4-2.5c-0.2-0.4-0.2-0.9,0.1-1.3l3.6-4.9c0.6-0.9,0.6-2,0-2.9l-3.6-4.9c-0.3-0.4-0.3-0.9-0.1-1.3l1.4-2.5
                c0.2-0.4,0.7-0.6,1.1-0.6l6,0.7c1,0.1,2-0.5,2.5-1.4l2.4-5.5c0.2-0.4,0.6-0.7,1.1-0.7h2.9C52,27.4,52.4,27.7,52.6,28.1z"
        />
        <circle class="st2" cx="50.1" cy="45.1" r="5.5" />
        <line class="st2" x1="68.8" y1="31.3" x2="80.4" y2="31.3" />
        <line class="st2" x1="18.2" y1="31.3" x2="30.2" y2="31.3" />
        <line class="st2" x1="67.3" y1="59.6" x2="80.4" y2="59.6" />
        <line class="st2" x1="18.2" y1="59.6" x2="31.8" y2="59.6" />
        <line class="st2" x1="69.4" y1="45.5" x2="80.4" y2="45.5" />
        <line class="st2" x1="18.2" y1="45.5" x2="30.4" y2="45.5" />
      </g>
      <g>
        <path
          class="st6"
          d="M93.8,69.1h-3.1c0-0.2,0.1-0.3,0.1-0.5V39.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8l0,28.7l-74.6,0l0-47.1
                l74.6,0v8.2c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-8.2c0-1.9-1.6-3.5-3.5-3.5H12.7c-1.9,0-3.5,1.6-3.5,3.5v47.1
                c0,0.2,0,0.4,0.1,0.5H6.2c-1.9,0-3.5,1.6-3.5,3.5V79c0,1.9,1.6,3.5,3.5,3.5h87.6c1.9,0,3.5-1.6,3.5-3.5v-6.4
                C97.2,70.6,95.7,69.1,93.8,69.1z M6.3,78.9l0-6.4l87.5,0l0,6.4H6.3z"
        />
        <path
          class="st6"
          d="M36.9,57.5l6-0.7c0.3,0,0.6,0.1,0.7,0.4l2.4,5.5c0.5,1.1,1.5,1.7,2.7,1.7h2.9c1.2,0,2.2-0.7,2.7-1.7l2.4-5.5
                c0.1-0.3,0.4-0.4,0.7-0.4l6,0.7c1.1,0.1,2.3-0.4,2.9-1.4l1.4-2.5c0.6-1,0.5-2.3-0.2-3.2l-3.6-4.9c-0.2-0.2-0.2-0.6,0-0.8l3.6-4.9
                c0.7-0.9,0.8-2.2,0.2-3.2l-1.4-2.5c-0.6-1-1.7-1.6-2.9-1.4l-6,0.7c-0.3,0-0.6-0.1-0.7-0.4l-2.4-5.5l0,0c-0.5-1.1-1.5-1.7-2.7-1.7
                h-2.9c-1.2,0-2.2,0.7-2.7,1.7l-2.4,5.5c-0.1,0.3-0.4,0.4-0.7,0.4l-6-0.7c-1.2-0.1-2.3,0.4-2.8,1.4l-1.4,2.5
                c-0.6,1-0.5,2.3,0.2,3.2l3.6,4.9c0.2,0.2,0.2,0.6,0,0.8l-3.6,4.9c-0.7,0.9-0.8,2.2-0.2,3.2L34,56C34.6,57,35.7,57.6,36.9,57.5z
                M39.1,47.5c1.1-1.5,1.1-3.5,0-4.9L35.8,38l1.1-1.9l5.6,0.6c1.8,0.2,3.5-0.8,4.3-2.5l2.3-5.2h2.1l2.3,5.2c0.7,1.7,2.5,2.7,4.3,2.5
                l5.6-0.6l1.1,1.9l-3.3,4.6c-1.1,1.5-1.1,3.5,0,4.9l3.3,4.6l-1.1,1.9l-5.6-0.6c-1.8-0.2-3.5,0.8-4.3,2.5L51.2,61H49l-2.3-5.2
                c-0.7-1.7-2.4-2.7-4.3-2.5l-5.6,0.6l-1.1-1.9L39.1,47.5z"
        />
        <path
          class="st6"
          d="M50.1,52.3c4,0,7.2-3.2,7.2-7.2s-3.2-7.2-7.2-7.2c-4,0-7.2,3.2-7.2,7.2S46.1,52.3,50.1,52.3z M50.1,41.3
                c2,0,3.7,1.7,3.7,3.7s-1.7,3.7-3.7,3.7s-3.7-1.7-3.7-3.7S48.1,41.3,50.1,41.3z"
        />
        <path
          class="st6"
          d="M80.4,29.6H68.8c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h11.5c1,0,1.8-0.8,1.8-1.8S81.3,29.6,80.4,29.6z"
        />
        <path
          class="st6"
          d="M18.2,33.1h12c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8h-12c-1,0-1.8,0.8-1.8,1.8S17.2,33.1,18.2,33.1z"
        />
        <path
          class="st6"
          d="M67.3,61.4h13.1c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H67.3c-1,0-1.8,0.8-1.8,1.8S66.3,61.4,67.3,61.4z"
        />
        <path
          class="st6"
          d="M18.2,61.4h13.6c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H18.2c-1,0-1.8,0.8-1.8,1.8S17.2,61.4,18.2,61.4z"
        />
        <path
          class="st6"
          d="M80.4,43.7h-11c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h11c1,0,1.8-0.8,1.8-1.8S81.3,43.7,80.4,43.7z"
        />
        <path
          class="st6"
          d="M18.2,47.2h12.2c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H18.2c-1,0-1.8,0.8-1.8,1.8S17.2,47.2,18.2,47.2z"
        />
      </g>
    </g>
    <g id="Ebene_6">
      <g class="st0">
        <line class="st7" x1="92" y1="18" x2="7.3" y2="18" />
        <line class="st7" x1="7.3" y1="82" x2="12.1" y2="18.4" />
        <line class="st7" x1="89.1" y1="45.8" x2="92" y2="82" />
        <line class="st7" x1="87" y1="19" x2="88.5" y2="35.8" />
        <rect x="33.6" y="40.7" class="st7" width="33" height="33" />
        <polyline class="st7" points="26,48.8 26,32.8 74.2,32.8 74.2,48.8 		" />
        <line class="st7" x1="35.5" y1="18" x2="40.1" y2="32.4" />
        <line class="st7" x1="59.6" y1="32.4" x2="64.7" y2="18" />
        <line class="st7" x1="39.6" y1="67.5" x2="59.4" y2="67.5" />
        <line class="st7" x1="39.6" y1="60.7" x2="59.4" y2="60.7" />
        <line class="st7" x1="39.6" y1="53.8" x2="59.4" y2="53.8" />
        <line class="st7" x1="39.6" y1="46.9" x2="59.4" y2="46.9" />
      </g>
      <g>
        <path
          class="st8"
          d="M89,44.1c-1,0.1-1.7,0.9-1.6,1.9l2.9,36.1c0.1,0.9,0.8,1.6,1.7,1.6c0,0,0.1,0,0.1,0c1-0.1,1.7-0.9,1.6-1.9
                l-2.9-36.1C90.8,44.7,89.9,44,89,44.1z"
        />
        <path
          class="st8"
          d="M88.7,37.5c1-0.1,1.7-0.9,1.6-1.9l-1.4-15.9H92c1,0,1.8-0.8,1.8-1.8S93,16.2,92,16.2H7.3
                c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h2.9L5.6,81.9c-0.1,1,0.7,1.8,1.6,1.9c0,0,0.1,0,0.1,0c0.9,0,1.7-0.7,1.7-1.6l4.7-62.4h20.5
                l3.6,11.3H26c-1,0-1.8,0.8-1.8,1.8v15.9c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8V34.6h44.7v14.2c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8
                V32.8c0-1-0.8-1.8-1.8-1.8H61.9l4-11.3h19.4l1.4,16.2c0.1,0.9,0.8,1.6,1.7,1.6C88.6,37.5,88.6,37.5,88.7,37.5z M58.2,31.1H41.6
                l-3.6-11.3h24.3L58.2,31.1z"
        />
        <path
          class="st8"
          d="M66.6,39h-33c-1,0-1.8,0.8-1.8,1.8v33c0,1,0.8,1.8,1.8,1.8h33c1,0,1.8-0.8,1.8-1.8v-33
                C68.4,39.8,67.6,39,66.6,39z M64.9,72H35.3V42.5h29.5V72z"
        />
        <path
          class="st8"
          d="M39.6,69.3h19.8c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H39.6c-1,0-1.8,0.8-1.8,1.8S38.6,69.3,39.6,69.3z"
        />
        <path
          class="st8"
          d="M39.6,62.4h19.8c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H39.6c-1,0-1.8,0.8-1.8,1.8S38.6,62.4,39.6,62.4z"
        />
        <path
          class="st8"
          d="M39.6,55.5h19.8c1,0,1.8-0.8,1.8-1.8S60.4,52,59.4,52H39.6c-1,0-1.8,0.8-1.8,1.8S38.6,55.5,39.6,55.5z"
        />
        <path
          class="st8"
          d="M39.6,48.7h19.8c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8H39.6c-1,0-1.8,0.8-1.8,1.8S38.6,48.7,39.6,48.7z"
        />
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts">
import "@/scss/svg.scss";
</script>
<style lang="scss">
.st0 {
  display: none;
}
.st1 {
  display: inline;
  fill: none;
  stroke: #1e2f55;
  stroke-width: 3.5;
  stroke-miterlimit: 10;
}
.st2 {
  display: inline;
  fill: none;
  stroke: #1e2f55;
  stroke-width: 3.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st3 {
  display: inline;
}
.st4 {
  fill: #1e2f55;
}
.st5 {
  fill: none;
  stroke: #1e2f55;
  stroke-width: 3.5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.st6 {
  display: inline;
  fill: #1e2f55;
}
.st7 {
  display: inline;
  fill: none;
  stroke: #1f2e54;
  stroke-width: 3.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st8 {
  fill: #1f2e54;
}
</style>
