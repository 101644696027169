<template>
  <svg
    class="custom-container-icon v-icon__svg"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 86.11 98"
  >
    <path
      class="cls-1"
      d="M85.19,38.23l-16.37-15.2c-.74-.69-1.99-.73-2.79-.1-.8,.63-.86,1.7-.12,2.38l15.45,14.34H4.74L43.05,3.62l14.92,14.03c.73,.69,1.98,.74,2.79,.11,.81-.63,.87-1.69,.13-2.38L45.41,.82c-1.26-1.07-3.38-1.13-4.77,.06L.98,38.17c-.95,.82-1.24,2.03-.72,3.1,.52,1.06,1.72,1.75,3.07,1.75H12.83v51.3c0,2.03,1.93,3.68,4.31,3.68h51.85c2.37,0,4.31-1.65,4.31-3.68V43.02h9.5c1.35,0,2.56-.69,3.07-1.75s.23-2.28-.66-3.05Zm-15.86,55.47c0,.2-.16,.36-.36,.36H17.13c-.2,0-.36-.16-.36-.36V42.88h52.56v50.81Z"
    />
    <path
      class="cls-1"
      d="M22.34,63.04c.51,0,1.01-.19,1.4-.58l13.16-13.16c.77-.77,.77-2.02,0-2.79s-2.02-.77-2.79,0l-13.16,13.16c-.77,.77-.77,2.02,0,2.79,.39,.39,.89,.58,1.4,.58Z"
    />
    <path
      class="cls-1"
      d="M21.34,84.09c.51,0,1.01-.19,1.4-.58L56.93,49.31c.77-.77,.77-2.02,0-2.79s-2.02-.77-2.79,0L19.94,80.72c-.77,.77-.77,2.02,0,2.79,.39,.39,.89,.58,1.4,.58Z"
    />
    <path
      class="cls-1"
      d="M33.72,89.77c.39,.39,.89,.58,1.4,.58s1.01-.19,1.4-.58l29.7-29.7c.77-.77,.77-2.02,0-2.79s-2.02-.77-2.79,0l-29.7,29.7c-.77,.77-.77,2.02,0,2.79Z"
    />
    <path
      class="cls-1"
      d="M62.03,78.7l-8.27,8.28c-.77,.77-.77,2.02,0,2.79,.39,.39,.89,.58,1.4,.58s1.01-.19,1.4-.58l8.27-8.28c.77-.77,.77-2.02,0-2.79s-2.02-.77-2.79,0Z"
    />
  </svg>
</template>

<script setup lang="ts">
import "@/scss/svg.scss";
</script>
<style lang="scss">
.cls-1 {
  fill: #1e2f55;
}
</style>
