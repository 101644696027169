<template>
  <div
    class="d-flex justify-center main-text pt-2"
    :class="{ clickable, disable: disabled }"
  >
    <span>{{ title }}</span>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  title: string;
  clickable?: boolean;
  disabled?: boolean;
}>();
</script>

<style lang="scss" scoped>
.main-text {
  color: #00295b;
  font-weight: bold;
  font-size: 1em;
}

.clickable {
  cursor: pointer;
}

.disable {
  pointer-events: none;
  color: grey;
}
</style>
